import React, { FC, ReactNode } from 'react'

type SummaryHeaderProps = {
    children: ReactNode
}

export const SummaryHeader: FC<SummaryHeaderProps> = ({ children }) => {
    return (
        <div className='flex font-bold bg-primary-1 py-2 px-4 text-xl text-white'>
            {children}
        </div>
    )
}
