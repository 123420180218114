import React, { useContext, useEffect, useMemo, useState } from "react";
import { EnergyContext } from "../../../context/EnergyContext";
import { msg } from "../../../locale/texts";
import {
  calculateEnergyEntries,
  formatNumber,
  formatNumberWithoutRounding,
  printNumber,
} from "../../../utils";
import { Button } from "../../Button";
import { Summary } from "../../Summary";
import { SummaryHeader } from "../../Summary/SummaryHeader";
import { SummaryRow } from "../../Summary/SummaryRow";
import { SummarySection } from "../../Summary/SummarySection";
import { DownloadExcelModal } from "./DownloadExcelModal";
import { getExcelExportDefaultData } from "../../../data";

type Grant = {
  energy: number;
  price: number;
  name: string;
  average: string;
};

export const Grant = ({
  data: {
    allFile: { edges },
  },
}: any) => {
  const { entries, fuelEntries } = useContext(EnergyContext);
  const [downloadModal, setDownloadModal] = useState(false);
  const [exportExcelData, setExportExcelData] = useState<Record<string, any>>(
    []
  );
  // const [exportJsonData, setExportJsonData] = useState<Record<string, any>>({})
  const [total, setTotal] = useState(0);

  const grants = useMemo(() => {
    const tempEnergies: any[] = [];
    let exportExcelDataTemp: Record<string, any> = getExcelExportDefaultData();
    // let exportJsonDataTemp: Record<string, any> = {}
    let temp = calculateEnergyEntries(entries);

    for (const e in temp) {
      console.log(temp);
      const temp_data = {
        amount: (
          temp[e].priceIncrease *
          0.3 *
          (Math.min(temp[e].consumptionWithoutLoadProfile, 1000000) +
            formatNumber(temp[e].consumptionWithLoadProfile, 8))
        ).toFixed(2),
        text: msg.grant.energyText,
        name: temp[e].name,
      };
      tempEnergies.push(temp_data);
      // exportJsonDataTemp[`${temp_data.name}verbrauch_Foerderzeitraum`] = formatNumberWithoutRounding(temp_data.amount, 4)
      // exportJsonDataTemp[`${temp_data.name}_Durchschnittsarbeitspreis_Foerderzeitraum`] = temp[e].averagePriceFunding
      // exportJsonDataTemp[`${temp_data.name}_Durchschnittsarbeitspreis_2021`] = formatNumber(temp[e].average.toFixed(2))

      // // EXCEL
      exportExcelDataTemp[temp_data.name] = {
        [`Ace${temp_data.name}verbrauchimfoerderzeitraum`]:
          formatNumberWithoutRounding(temp[e].consumptionTogether, 8),
        [`AceDurchschnittspreis${temp_data.name.toLowerCase()}zeitraum`]:
          formatNumber(temp[e].averagePriceFunding, 4),
        [`AceDurchschnittspreis${temp_data.name.toLowerCase()}vergleich`]:
          formatNumber(temp[e].average, 4),
      };
    }

    let fuels = 0;
    let fuelConsumed = 0;
    let fuelAverage = 0;

    fuelEntries.map((fuelEntry) => {
      fuelConsumed += formatNumber(fuelEntry.consumption, 8);
    });

    fuelEntries.map((fuelEntry) => {
      const fuelAveragePrice = formatNumber(fuelEntry.averagePrice, 8);
      if (fuelAveragePrice > fuelEntry.priceConstant) {
        fuels += Math.max(
          formatNumber(fuelEntry.consumption, 8) *
            (formatNumber(fuelEntry.averagePrice, 8) / 1.2 -
              formatNumber(fuelEntry.constant, 8) -
              0.6),
          0
        );

        fuelAverage += Math.max(
          (formatNumber(fuelEntry.averagePrice, 8) / 1.2 -
            formatNumber(fuelEntry.constant, 8)) *
            formatNumber(formatNumber(fuelEntry.consumption) / fuelConsumed, 8),
          0
        );

        // fuelAverage += (formatNumber(fuelEntry.averagePrice) / 1.2 - formatNumber(fuelEntry.constant) - 0.6)
        // exportJsonDataTemp[`${fuelEntry.name}verbrauch_Foerderzeitraum`] = formatNumber(fuelEntry.consumption, 0)
        // exportJsonDataTemp[`${fuelEntry.name}_Durchschnittsnettopreis_Foerderzeitraum`] = formatNumber(Math.max(formatNumber(fuelEntry.consumption) * (formatNumber(fuelEntry.averagePrice) / 1.2 - formatNumber(fuelEntry.constant)), 0).toFixed(2))
      }
    });

    let itemExcelData: Record<string, any> = {};
    itemExcelData[`Ace${msg.fuelTypes.title}verbrauchzeitraum`] = formatNumber(
      fuelConsumed,
      0
    );
    itemExcelData[
      `AceDurchschnittspreis${msg.fuelTypes.title.toLowerCase()}zeitraum`
    ] = formatNumber(fuelAverage, 4);
    exportExcelDataTemp[msg.fuelTypes.title] = itemExcelData;

    tempEnergies.push({
      amount: formatNumber(formatNumber(fuels) * 0.3, 8).toFixed(2),
      text: msg.grant.fuelText,
      name: msg.fuelTypes.title,
    });

    setExportExcelData(exportExcelDataTemp);
    // setExportJsonData(exportJsonDataTemp)
    return tempEnergies;
  }, []);

  useEffect(() => {
    const temp_total = parseFloat(
      grants
        .reduce((t, curr) => (t += formatNumber(curr.amount, 8)), 0)
        .toFixed(2)
    );
    setTotal(temp_total > 2000 ? Math.min(temp_total, 400000) : 0);
  }, [grants]);

  // const downloadJSON = () => {
  //     exportDataToJSON(exportJsonData)
  // }

  const downloadEXCEL = () => {
    setDownloadModal(true);
    // exportDataToEXCEL(exportExcelData, `${window.location.protocol}//${window.location.hostname}:${window.location.port}${edges[0].node.publicURL}`)
  };

  return (
    <div className="gap-x-8 text-right">
      <Summary>
        <SummarySection borderBottom>
          {downloadModal && (
            <DownloadExcelModal
              setDownloadModal={setDownloadModal}
              exportExcelData={exportExcelData}
              excelPublicURL={edges[0].node.publicURL}
            />
          )}

          <SummaryHeader>
            <p className="w-full text-left">{msg.grant.title}</p>
          </SummaryHeader>

          {grants &&
            grants.map((grant) => (
              <SummaryRow
                key={`grant-${grant.name}`}
                title={`${grant.name}`}
                text={`${grant.text}`}
              >
                <span
                  className={`appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline font-bold`}
                >
                  {printNumber(grant.amount)}{" "}
                  <span className="text-sm">EUR</span>
                </span>
              </SummaryRow>
            ))}
        </SummarySection>

        <SummaryRow
          title={`${msg.grant.total}`}
          text={`${
            formatNumber(total) > 2000
              ? msg.grant.totalTextMore
              : `<span class="text-red-700">${msg.grant.totalTextLess}</span>`
          }`}
        >
          <span
            className={`appearance-none rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline font-bold`}
          >
            {printNumber(total, 2, 0)} <span className="text-sm">EUR</span>
          </span>
        </SummaryRow>
      </Summary>

      <div className="flex justify-end">
        <Button
          onClick={() => downloadEXCEL()}
          className={`bg-primary-500 hover:bg-primary-1 mr-5`}
        >
          Download Excel
        </Button>

        {/* <Button
                    onClick={() => downloadJSON()}
                    className={`bg-primary-500 hover:bg-primary-1`}
                >
                    Download JSON
                </Button> */}
      </div>
    </div>
  );
};
