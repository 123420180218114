import React, { FC, ReactNode } from 'react'

type SummaryProps = {
    children: ReactNode
}

export const Summary: FC<SummaryProps> = ({ children }) => {
    return (
        <div className='w-full rounded-2xl border border-primary-1 mb-3 overflow-hidden'>
            {children}
        </div>
    )
}
