import { Link } from 'gatsby'
import React, { FC } from 'react'

const StepButton: FC<StepButtonType> = ({ link, msg, right = false }) => (
    <Link
        to={link}
        className={(right ? 'mr-auto' : 'ml-auto') + ` border border-primary-1 text-primary-1 hover:border-primary-500 hover:text-white hover:bg-primary-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
    >
        {msg}
    </Link>
)

type StepButtonType = {
    link: string
    msg: string
    right?: boolean
}

type StepProps = {
    title: string
    text: string[]
    children: any
    nextStep?: StepButtonType
    prevStep?: StepButtonType
}

export const Step: FC<StepProps> = ({
    title,
    text,
    nextStep,
    prevStep,
    children
}) => {
    return (
        <div>
            <div className='py-3'>
                <h3 className='font-semibold'>{title}</h3>
                <div className='pt-3 text-sm'>
                    {
                        text && text.map((t, i) =>
                            <p
                                className="pre-line"
                                key={`step-text-${i}`}
                                dangerouslySetInnerHTML={{ __html: t }}
                            />
                        )
                    }
                </div>
            </div>

            {children}

            <div className='flex my-6 mt-auto'>
                {prevStep && <StepButton msg={prevStep.msg} link={prevStep.link} right={prevStep.right} />}
                {nextStep && <StepButton msg={nextStep.msg} link={nextStep.link} right={nextStep.right} />}
            </div>
        </div >
    )
}

