import React, { FC, ReactNode } from 'react'

type EntryType = {
    title: string
    text?: string
    className?: string
    allowEmptyText?: boolean
    usageEntry?: boolean
    children: ReactNode
    textClassName?: string
}
export const Entry: FC<EntryType> = ({ title, text, children, className, allowEmptyText, usageEntry, textClassName }) => {

    if (usageEntry) {
        return <div className={`px-4 my-2 ${className}`}>
            <div className='flex flex-col sm:flex-row gap-x-8'>
                <div className='w-full sm:w-6/12 md:w-5/12 flex items-center text-left'>
                    {title}
                </div>
                <div className='w-full sm:w-6/12 md:w-3/12 flex items-center pr-4'>
                    {children}
                </div>
                <div className={`w-full sm:w-0 md:w-4/12 text-sm flex items-center text-primary-500 text-left ${textClassName ? textClassName : ''}`} dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            </div>
        </div>
    }

    if (allowEmptyText) {
        return <div className={`px-4 my-2 ${className}`}>
            <div className='flex flex-col sm:flex-row gap-x-8'>
                <div className='w-full sm:w-2/6 flex items-center text-left'>
                    {title}
                </div>
                <div className='w-full sm:w-1/6 flex items-center pr-4'>
                    {children}
                </div>
                <div className={`w-full sm:w-3/6 text-sm flex items-center text-primary-500 text-left ${textClassName ? textClassName : ''}`} dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            </div>
        </div>
    }

    if (!text) {
        return <div className={`px-4 my-2 ${className}`}>
            <div className='flex flex-col sm:flex-row gap-x-8'>
                <div className='w-full sm:w-1/2 flex items-center text-left'>
                    {title}
                </div>
                <div className='w-full sm:w-1/2 flex items-center md:justify-start pr-4'>
                    {children}
                </div>
            </div>
        </div>
    }

    return <div className={`px-4 my-2 ${className}`}>
        <div className='flex flex-col sm:flex-row gap-x-8'>
            <div className='w-full sm:w-2/6 flex items-center text-left'>
                {title}
            </div>
            <div className='w-full sm:w-1/6 flex items-center pr-4'>
                {children}
            </div>
            <div className={`w-full sm:w-3/6 text-sm flex items-center text-primary-500 text-left ${textClassName ? textClassName : ''}`} dangerouslySetInnerHTML={{ __html: text ?? '' }} />
        </div>
    </div>
}