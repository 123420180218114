import React, { useState, ChangeEvent, useEffect, useRef } from 'react'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { msg } from '../../../locale/texts'
import { exportDataToEXCEL } from '../../../utils'
import { Button } from '../../Button'
import { Input } from '../../Input'

export const DownloadExcelModal = ({ exportExcelData, excelPublicURL, setDownloadModal }: any) => {
  const modalRef = useRef(null)
  const [fileName, setFileName] = useState('')
  const [fileNameError, setFileNameError] = useState<string | undefined>(undefined)

  const isFileNameValid = (fileName: string) => {
    const rgText1 = /^(?!\.)+/
    const rgText2 = /^[^\|\*\?\\:<>/$"]*$/
    const rgText3 = /[^\.\|\*\?\\:<>/$"]+$/

    if (!fileName) {
      setFileNameError(undefined)
      return
    }

    if (!rgText1.test(fileName)) {
      setFileNameError(msg.export.downloadModal.ruleStartWithDot)
      return false
    }

    if (!rgText2.test(fileName)) {
      setFileNameError(msg.export.downloadModal.ruleSpecial)
      return false
    }

    if (!rgText3.test(fileName)) {
      setFileNameError(msg.export.downloadModal.ruleEndWithDot)
      return false
    }

    setFileNameError(undefined)
    return true
  }

  useEffect(() => {
    isFileNameValid(fileName)
  }, [fileName])


  const downloadEXCEL = () => {

    if (!fileName) {
      setFileNameError(msg.export.downloadModal.ruleEmpty)
      return
    }

    if (!isFileNameValid(fileName)) {
      return
    }

    exportDataToEXCEL(exportExcelData, `${window.location.protocol}//${window.location.hostname}:${window.location.port}${excelPublicURL}`, fileName)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      setFileName(e.target.value)
    }
  }

  const closeModal = () => {
    setDownloadModal(false)
  }

  useClickOutside({ ref: modalRef, action: closeModal })

  return (
    <div id="ExportExcelModal" tabIndex={-1} aria-hidden="true" className="flex items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full">
      <div className="relative w-full max-w-2xl mx-auto z-50" ref={modalRef}>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              {msg.export.downloadModal.title}
            </h3>
            <button onClick={() => closeModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" >
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              <span className="sr-only" >Close modal</span>
            </button>
          </div>
          <div className="p-6 flex flex-col">
            <Input id={`file-name`} name={`file-name`} value={fileName} onChange={onChange} timeout={0} />
            {fileNameError && <p className='text-red-500 text-left' dangerouslySetInnerHTML={{ __html: fileNameError }} />}
          </div>
          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <Button
              onClick={() => downloadEXCEL()}
              className={`bg-primary-500 hover:bg-primary-1 ml-auto`}
            >
              Download EXCEL
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
