import * as React from "react"
import { graphql } from "gatsby"
import type { HeadFC } from "gatsby"
import { Step4 } from "../components/Step4"

const IndexPage = (props: any) => {
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        setMounted(true)
    }, [])

    return mounted ? <Step4  {...props} /> : null
}


export const query = graphql`
    query FileQuery {
        allFile(filter: {extension: {eq: "xlsx"}}) {
            edges {
                node {
                id
                absolutePath
                name
                relativePath
                publicURL
                }
            }
        }
    }
`

export default IndexPage

export const Head: HeadFC = () => (<title>Home Page</title>)
