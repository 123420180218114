import React, { FC, ReactNode } from 'react'
import { Entry } from '../Entry'

type SummaryRowProps = {
    title: string
    value?: string
    text?: string
    allowEmptyText?: boolean
    children?: ReactNode
}

export const SummaryRow: FC<SummaryRowProps> = ({ title, value, text, allowEmptyText, children }) => {
    if (allowEmptyText) {
        return <Entry className={`px-4 py-1 my-0 hover:bg-primary-50`} title={title} text={text} allowEmptyText={allowEmptyText}>
            {value ? (<span className='font-bold'>{value}</span>) : undefined}
            {children}
        </Entry>
    }

    if (!text) {
        return (
            <div className='flex px-4 py-1 hover:bg-primary-50'>
                <div className='w-2/3'>{title}</div>
                <div className='w-1/3 text-right font-bold'>{value}</div>
            </div>
        )
    }

    return <Entry className={`px-4 py-1 my-0 hover:bg-primary-50`} title={title} text={text}>
        {value ? (<span className='font-bold'>{value}</span>) : undefined}
        {children}
    </Entry>
}
