import React, { FC, ReactNode } from 'react'

type SummarySectionProps = {
    borderBottom?: boolean
    children: ReactNode
}

export const SummarySection: FC<SummarySectionProps> = ({ borderBottom, children }) => {
    return (
        <div className={`${borderBottom ? 'border-b-2 border-primary-500' : ''}`}>
            {children}
        </div>
    )
}
