import React from 'react'
import { msg } from '../../locale/texts'
import { Step } from '../Step'
import { Grant } from './Grant'

export const Step4 = (props: any) => {

  const prevStep = {
    msg: msg.links.backStep3,
    link: '/step3',
    right: true
  }

  return (
    <Step title={msg.step4.title} text={msg.step4.text} prevStep={prevStep}>
      <Grant {...props} />
    </Step>
  )
}