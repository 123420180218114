import React, { FC, ChangeEvent, useState, useEffect, useRef } from 'react'
import { formatNumber, formatNumberForInput, printNumber } from '../utils'
// import { formatNumber } from '../utils'

type InputProps = {
    value: any
    onChange: (value: any) => void
    name: string
    type?: 'number' | 'text' | 'checkbox'
    className?: string
    id?: string
    label?: string
    placeholder?: string
    disabled?: boolean
    minValue?: number
    step?: number
    decimals?: number
    defaultValue?: number | undefined
    timeout?: number
}

export const Input: FC<InputProps> = ({
    value,
    name,
    type = 'text',
    onChange,
    className,
    label,
    placeholder = '...',
    disabled,
    id,
    minValue = 0,
    step = 'any',
    decimals = 2,
    defaultValue = undefined,
    timeout = 1000
}) => {
    const inputEl = useRef<any>(null);
    const [newEvent, setNewEvent] = useState<ChangeEvent<any>>()
    const [inputFocus, setInputFocus] = useState<boolean>()

    useEffect(() => {
        if (inputEl && inputEl.current) {
            inputEl.current.timer = setTimeout(() => {
                onChange(newEvent)
                setNewEvent(undefined)
            }, timeout)
        }
        return () => {
            clearTimeout(inputEl?.current?.timer)
        }
    }, [newEvent])

    // useEffect(() => {
    //     if (inputEl && inputEl.current) {
    //         inputEl.current.timer = setTimeout(() => {
    //             onChange(newEvent)
    //         }, 1000)
    //     }
    //     return () => {
    //         clearTimeout(inputEl?.current?.timer)
    //     }
    // }, [inputVal])

    const handleNumberInputChange = (e: ChangeEvent<any>) => {
        if (e.target && e.target.value) {
            if (e.target.value < 0) {
                e.target.value = 0
            }
        }
        setNewEvent(e)
    }

    const onBlur = () => setInputFocus(false)
    const onFocus = () => setInputFocus(true)

    if (type === 'number') {

        return (
            <>
                {label &&
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={name}>
                        {label}
                    </label>
                }
                {
                    inputFocus
                        ? <input
                            ref={inputEl}
                            id={id ?? name}
                            className={`shadow appearance-none border w-full rounded py-2 px-3 text-gray-700 leading-tight ${!disabled && `active:outline-none active:shadow-outline`} ${disabled && 'opacity-70 bg-slate-300'} ${className}`}
                            name={name}
                            type={type}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={newEvent?.target.value ?? formatNumberForInput(value, decimals, defaultValue) ?? ''}
                            onChange={handleNumberInputChange}
                            onBlur={onBlur}
                            min={minValue}
                            step={step}
                        />
                        : <input
                            ref={inputEl}
                            id={id ?? name}
                            className={`shadow appearance-none border w-full rounded py-2 px-3 text-gray-700 leading-tight ${!disabled && `active:outline-none active:shadow-outline`} ${disabled && 'opacity-70 bg-slate-300'} ${className}`}
                            name={name}
                            placeholder={placeholder}
                            disabled={disabled}
                            value={newEvent?.target.value ?? printNumber(value, decimals, defaultValue) ?? ''}
                            onChange={handleNumberInputChange}
                            min={minValue}
                            onFocus={onFocus}
                        />
                }

            </>
        )
    }

    return (
        <>
            {label &&
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={name}>
                    {label}
                </label>
            }
            <input
                ref={inputEl}
                id={id ?? name}
                className={`shadow appearance-none border w-full rounded py-2 px-3 text-gray-700 leading-tight ${!disabled && `active:outline-none active:shadow-outline`} ${disabled && 'opacity-70 bg-slate-300'} ${className}`}
                type={type}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                value={newEvent?.target.value ?? value ?? ''}
                onChange={(e) => setNewEvent(e)}
            />
        </>
    )
}
